import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Impressum from "../components/impressum"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Impressum />
  </Layout>
)

export default SecondPage
